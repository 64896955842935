// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2022-q-4-google-ads-onport-dropship-js": () => import("./../../../src/pages/2022_Q4_google_ads_onport_dropship.js" /* webpackChunkName: "component---src-pages-2022-q-4-google-ads-onport-dropship-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-api-pipeline-js": () => import("./../../../src/pages/api-pipeline.js" /* webpackChunkName: "component---src-pages-api-pipeline-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-composable-js": () => import("./../../../src/pages/composable.js" /* webpackChunkName: "component---src-pages-composable-js" */),
  "component---src-pages-contacts-book-demo-js": () => import("./../../../src/pages/contacts/book-demo.js" /* webpackChunkName: "component---src-pages-contacts-book-demo-js" */),
  "component---src-pages-contacts-curated-distribution-network-js": () => import("./../../../src/pages/contacts/curated-distribution-network.js" /* webpackChunkName: "component---src-pages-contacts-curated-distribution-network-js" */),
  "component---src-pages-contacts-enterprise-js": () => import("./../../../src/pages/contacts/enterprise.js" /* webpackChunkName: "component---src-pages-contacts-enterprise-js" */),
  "component---src-pages-contacts-get-pricing-js": () => import("./../../../src/pages/contacts/get-pricing.js" /* webpackChunkName: "component---src-pages-contacts-get-pricing-js" */),
  "component---src-pages-contacts-lets-talk-js": () => import("./../../../src/pages/contacts/lets-talk.js" /* webpackChunkName: "component---src-pages-contacts-lets-talk-js" */),
  "component---src-pages-contacts-marketplaces-js": () => import("./../../../src/pages/contacts/marketplaces.js" /* webpackChunkName: "component---src-pages-contacts-marketplaces-js" */),
  "component---src-pages-contacts-partners-js": () => import("./../../../src/pages/contacts/partners.js" /* webpackChunkName: "component---src-pages-contacts-partners-js" */),
  "component---src-pages-contacts-retailer-model-js": () => import("./../../../src/pages/contacts/retailer-model.js" /* webpackChunkName: "component---src-pages-contacts-retailer-model-js" */),
  "component---src-pages-contacts-sellers-js": () => import("./../../../src/pages/contacts/sellers.js" /* webpackChunkName: "component---src-pages-contacts-sellers-js" */),
  "component---src-pages-contacts-use-cases-a-curated-distribution-network-for-your-brand-js": () => import("./../../../src/pages/contacts/use-cases/a-curated-distribution-network-for-your-brand.js" /* webpackChunkName: "component---src-pages-contacts-use-cases-a-curated-distribution-network-for-your-brand-js" */),
  "component---src-pages-contacts-use-cases-automate-marketplace-operations-js": () => import("./../../../src/pages/contacts/use-cases/automate-marketplace-operations.js" /* webpackChunkName: "component---src-pages-contacts-use-cases-automate-marketplace-operations-js" */),
  "component---src-pages-contacts-use-cases-create-a-marketplace-js": () => import("./../../../src/pages/contacts/use-cases/create-a-marketplace.js" /* webpackChunkName: "component---src-pages-contacts-use-cases-create-a-marketplace-js" */),
  "component---src-pages-contacts-use-cases-expand-product-offering-js": () => import("./../../../src/pages/contacts/use-cases/expand-product-offering.js" /* webpackChunkName: "component---src-pages-contacts-use-cases-expand-product-offering-js" */),
  "component---src-pages-contacts-use-cases-expand-your-market-js": () => import("./../../../src/pages/contacts/use-cases/expand-your-market.js" /* webpackChunkName: "component---src-pages-contacts-use-cases-expand-your-market-js" */),
  "component---src-pages-data-processing-agreement-js": () => import("./../../../src/pages/data-processing-agreement.js" /* webpackChunkName: "component---src-pages-data-processing-agreement-js" */),
  "component---src-pages-enterprise-contact-js": () => import("./../../../src/pages/enterprise-contact.js" /* webpackChunkName: "component---src-pages-enterprise-contact-js" */),
  "component---src-pages-enterprise-js": () => import("./../../../src/pages/enterprise.js" /* webpackChunkName: "component---src-pages-enterprise-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-faqs-rebranding-js": () => import("./../../../src/pages/faqs-rebranding.js" /* webpackChunkName: "component---src-pages-faqs-rebranding-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-testt-js": () => import("./../../../src/pages/features-testt.js" /* webpackChunkName: "component---src-pages-features-testt-js" */),
  "component---src-pages-headless-download-js": () => import("./../../../src/pages/headless/download.js" /* webpackChunkName: "component---src-pages-headless-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-create-product-js": () => import("./../../../src/pages/onboarding/create-product.js" /* webpackChunkName: "component---src-pages-onboarding-create-product-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-onboarding-order-js": () => import("./../../../src/pages/onboarding/order.js" /* webpackChunkName: "component---src-pages-onboarding-order-js" */),
  "component---src-pages-onboarding-payments-js": () => import("./../../../src/pages/onboarding/payments.js" /* webpackChunkName: "component---src-pages-onboarding-payments-js" */),
  "component---src-pages-onboarding-returns-js": () => import("./../../../src/pages/onboarding/returns.js" /* webpackChunkName: "component---src-pages-onboarding-returns-js" */),
  "component---src-pages-onboarding-shipping-js": () => import("./../../../src/pages/onboarding/shipping.js" /* webpackChunkName: "component---src-pages-onboarding-shipping-js" */),
  "component---src-pages-onboarding-tax-and-invoicing-js": () => import("./../../../src/pages/onboarding/tax-and-invoicing.js" /* webpackChunkName: "component---src-pages-onboarding-tax-and-invoicing-js" */),
  "component---src-pages-onport-logistics-js": () => import("./../../../src/pages/onport-logistics.js" /* webpackChunkName: "component---src-pages-onport-logistics-js" */),
  "component---src-pages-partners-channel-status-js": () => import("./../../../src/pages/partners/channel-status.js" /* webpackChunkName: "component---src-pages-partners-channel-status-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-partners-questionnaire-js": () => import("./../../../src/pages/partners-questionnaire.js" /* webpackChunkName: "component---src-pages-partners-questionnaire-js" */),
  "component---src-pages-partners-shipping-status-js": () => import("./../../../src/pages/partners/shipping-status.js" /* webpackChunkName: "component---src-pages-partners-shipping-status-js" */),
  "component---src-pages-partners-vendor-status-js": () => import("./../../../src/pages/partners/vendor-status.js" /* webpackChunkName: "component---src-pages-partners-vendor-status-js" */),
  "component---src-pages-privacy-candidate-js": () => import("./../../../src/pages/privacy-candidate.js" /* webpackChunkName: "component---src-pages-privacy-candidate-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-onport-unite-js": () => import("./../../../src/pages/privacy-onport-unite.js" /* webpackChunkName: "component---src-pages-privacy-onport-unite-js" */),
  "component---src-pages-rev-ops-info-price-js": () => import("./../../../src/pages/rev-ops-info-price.js" /* webpackChunkName: "component---src-pages-rev-ops-info-price-js" */),
  "component---src-pages-support-packages-js": () => import("./../../../src/pages/support-packages.js" /* webpackChunkName: "component---src-pages-support-packages-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-sellers-js": () => import("./../../../src/pages/test/sellers.js" /* webpackChunkName: "component---src-pages-test-sellers-js" */),
  "component---src-pages-thanks-book-demo-js": () => import("./../../../src/pages/thanks/book-demo.js" /* webpackChunkName: "component---src-pages-thanks-book-demo-js" */),
  "component---src-pages-thanks-enterprise-js": () => import("./../../../src/pages/thanks/enterprise.js" /* webpackChunkName: "component---src-pages-thanks-enterprise-js" */),
  "component---src-pages-thanks-get-pricing-js": () => import("./../../../src/pages/thanks/get-pricing.js" /* webpackChunkName: "component---src-pages-thanks-get-pricing-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thanks-lets-talk-js": () => import("./../../../src/pages/thanks/lets-talk.js" /* webpackChunkName: "component---src-pages-thanks-lets-talk-js" */),
  "component---src-pages-thanks-partners-js": () => import("./../../../src/pages/thanks/partners.js" /* webpackChunkName: "component---src-pages-thanks-partners-js" */),
  "component---src-pages-thanks-retailer-model-js": () => import("./../../../src/pages/thanks/retailer-model.js" /* webpackChunkName: "component---src-pages-thanks-retailer-model-js" */),
  "component---src-pages-thanks-sellers-js": () => import("./../../../src/pages/thanks/sellers.js" /* webpackChunkName: "component---src-pages-thanks-sellers-js" */),
  "component---src-pages-thanks-use-cases-a-curated-distribution-network-for-your-brand-js": () => import("./../../../src/pages/thanks/use-cases/a-curated-distribution-network-for-your-brand.js" /* webpackChunkName: "component---src-pages-thanks-use-cases-a-curated-distribution-network-for-your-brand-js" */),
  "component---src-pages-thanks-use-cases-automate-marketplace-operations-js": () => import("./../../../src/pages/thanks/use-cases/automate-marketplace-operations.js" /* webpackChunkName: "component---src-pages-thanks-use-cases-automate-marketplace-operations-js" */),
  "component---src-pages-thanks-use-cases-create-a-marketplace-js": () => import("./../../../src/pages/thanks/use-cases/create-a-marketplace.js" /* webpackChunkName: "component---src-pages-thanks-use-cases-create-a-marketplace-js" */),
  "component---src-pages-thanks-use-cases-expand-product-offering-js": () => import("./../../../src/pages/thanks/use-cases/expand-product-offering.js" /* webpackChunkName: "component---src-pages-thanks-use-cases-expand-product-offering-js" */),
  "component---src-pages-thanks-use-cases-expand-your-market-js": () => import("./../../../src/pages/thanks/use-cases/expand-your-market.js" /* webpackChunkName: "component---src-pages-thanks-use-cases-expand-your-market-js" */),
  "component---src-pages-thanksv-2-js": () => import("./../../../src/pages/thanksv2.js" /* webpackChunkName: "component---src-pages-thanksv-2-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-feature-category-js": () => import("./../../../src/templates/featureCategory.js" /* webpackChunkName: "component---src-templates-feature-category-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-partner-category-js": () => import("./../../../src/templates/partnerCategory.js" /* webpackChunkName: "component---src-templates-partner-category-js" */),
  "component---src-templates-partner-js": () => import("./../../../src/templates/partner.js" /* webpackChunkName: "component---src-templates-partner-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/useCase.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

